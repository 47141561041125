import React, { Component } from 'react';
import troop from "../assets/troop.webp";
import yoda from "../assets/yoda.webp";
import Lightsaber from './Lightsaber';

class Welcomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDarkMode: document.documentElement.classList.contains('dark'),
            active: false
        };
    }

    updateDarkModeStatus = () => {
        const isDarkMode = document.documentElement.classList.contains("dark");
        this.setState({ isDarkMode });
    }

    componentDidMount() {
        this.observer = new MutationObserver(() => {
            this.updateDarkModeStatus();
        })

        this.observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ["class"]
        });
    }

    toggleLightsaber = () => {
        this.setState(prevState => ({
            active: !prevState.active // Toggle lightsaber on and off
        }));
    };


    render() {
        const { isDarkMode, active } = this.state;
        

        // h-3/5 w-2/5

        return (
            <header className="h-screen flex bg-stone-200 dark:bg-gray-900 dark:text-zinc-100 text-zinc-600 transition-all duration-300 ease-in-out">
                <section className='flex flex-col px-5 h-4/6 text-left my-auto mx-auto justify-between md:w-m sm:w-s sm:h-3/5 md:h-3/5 lg:h-3/5 lg:w-l 2xl:h-3/5 2xl:w-xl'>
                    <div className='flex flex-row relative'>
                        <h1 className='text-3xl text-zinc-800 dark:text-zinc-100 transition-all duration-300 ease-in-out md:text-4xl 2xl:text-4xl'>{isDarkMode ? "Hello Sith!" : "Hello Jedi!"}</h1>
                        <img className='opacity-0 dark:opacity-100 absolute w-12 h-auto -top-1 2xl:left-44 left-36 ml-2 md:left-44' src={troop} alt="Wave Hand" />
                        <img className='dark:opacity-0 opacity-100 absolute w-20 h-auto -top-5 2xl:left-44 left-36 ml-2 md:left-44' src={yoda} alt="Wave Hand" />
                    </div>
                    <div className='flex flex-col'>
                        <h2 className='dark:text-zinc-100 text-zinc-800 text-3xl font-semibold md:text-4xl lg:text-5xl 2xl:text-5xl'>I'm <span className=''>Çağatay Palaz</span></h2>
                        <h3 className='2xl:text-2xl lg:text-2xl text-xl dark:text-gray-400 mt-2'>
                            I'm a full-stack JavaScript JEDI, wielding the power of <a className='transition-colors duration-300 ease-in-out underline dark:decoration-sky-400 decoration-sky-600' href='https://react.dev/' target='_blank' rel="noreferrer">React</a>, <a className='transition-colors duration-300 ease-in-out underline dark:decoration-green-300 decoration-green-600' href="https://nodejs.org/" target='_blank' rel="noreferrer">Node.js</a>, <a href="https://tailwindcss.com/" target='_blank' rel="noreferrer" className='transition-colors duration-300 ease-in-out underline dark:decoration-cyan-200 decoration-cyan-500' >Tailwind CSS</a>, and many other libraries from the vast coding universe.
                        </h3>


                    </div>
                    <div>
                        <h3 className='2xl:text-2xl lg:text-2xl text-xl dark:text-gray-400 mt-2'>I craft seamless web experiences and keep bugs in check with my coding <span onClick={this.toggleLightsaber} className='cursor-pointer underline dark:decoration-red-500 decoration-blue-500'>lightsaber</span>!
                        </h3>
                        <div className='mx-5 my-5'>
                            <Lightsaber active={active} darkMode={isDarkMode} toggleLightsaber={this.toggleLightsaber} />
                        </div>
                    </div>

                    <div className='flex flex-col justify-start lg:flex-row'>
                        <a className='lg:self-center self-start cursor-pointer underline dark:text-gray-600 dark:hover:text-gray-400 text-gray-400 hover:text-gray-600 transition-colors duration-300 ease-in-out ' href='mailto:contact@cagataypalaz.com'>contact@cagataypalaz.com</a>
                        <div className='2xl:mx-2 lg:ml-3 lg:mt-0 -ml-1.5 mt-3 flex flex-row justify-start'>
                            <a className='mr-1' href="https://www.instagram.com/cagsthedev/" target='_blank' rel="noreferrer">
                                <svg className='dark:fill-gray-600 fill-gray-400 hover:fill-gray-600 dark:hover:fill-gray-400 transition-colors duration-300 ease-in-out' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 64 64">
                                    <path d="M 31.820312 12 C 13.438312 12 12 13.439312 12 31.820312 L 12 32.179688 C 12 50.560688 13.438313 52 31.820312 52 L 32.179688 52 C 50.561688 52 52 50.560688 52 32.179688 L 52 32 C 52 13.452 50.548 12 32 12 L 31.820312 12 z M 43.994141 18 C 45.099141 17.997 45.997 18.889141 46 19.994141 C 46.003 21.099141 45.110859 21.997 44.005859 22 C 42.900859 22.003 42.003 21.110859 42 20.005859 C 41.997 18.900859 42.889141 18.003 43.994141 18 z M 31.976562 22 C 37.498562 21.987 41.987 26.454563 42 31.976562 C 42.013 37.498562 37.545437 41.987 32.023438 42 C 26.501437 42.013 22.013 37.545437 22 32.023438 C 21.987 26.501437 26.454563 22.013 31.976562 22 z M 31.986328 26 C 28.672328 26.008 25.992 28.701625 26 32.015625 C 26.008 35.328625 28.700672 38.008 32.013672 38 C 35.327672 37.992 38.008 35.299328 38 31.986328 C 37.992 28.672328 35.299328 25.992 31.986328 26 z"></path>
                                </svg>
                            </a>
                            <a className='mr-1' href="https://www.linkedin.com/in/cagataypalaz/" target='_blank' rel="noreferrer">
                                <svg className='dark:fill-gray-600 fill-gray-400 hover:fill-gray-600 dark:hover:fill-gray-400 transition-colors duration-300 ease-in-out' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 64 64">
                                    <path d="M40.227,12C51.145,12,52,12.854,52,23.773v16.453C52,51.145,51.145,52,40.227,52H23.773C12.855,52,12,51.145,12,40.227	V23.773C12,12.854,12.855,12,23.773,12H40.227z M25.029,43V26.728h-5.057V43H25.029z M22.501,24.401	c1.625,0,2.947-1.322,2.947-2.949c0-1.625-1.322-2.947-2.947-2.947c-1.629,0-2.949,1.32-2.949,2.947S20.87,24.401,22.501,24.401z M44,43v-8.925c0-4.382-0.946-7.752-6.067-7.752c-2.46,0-4.109,1.349-4.785,2.628H33.08v-2.223h-4.851V43h5.054v-8.05	c0-2.122,0.405-4.178,3.036-4.178c2.594,0,2.628,2.427,2.628,4.315V43H44z"></path>
                                </svg>
                            </a>
                            <a className='mr-1' href="https://github.com/CagsTheDev" target='_blank' rel="noreferrer">
                                <svg className='dark:fill-gray-600 fill-gray-400 hover:fill-gray-600 dark:hover:fill-gray-400 transition-colors duration-300 ease-in-out' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 64 64">
                                    <path d="M32,10c12.15,0,22,9.85,22,22c0,9.768-6.369,18.045-15.179,20.916c0.002-0.008,0.006-0.021,0.006-0.021	s-1.485-0.696-1.453-1.938c0.035-1.367,0-4.556,0-5.727c0-2.01-1.272-3.434-1.272-3.434s9.977,0.112,9.977-10.533	c0-4.107-2.147-6.245-2.147-6.245s1.128-4.385-0.39-6.245c-1.701-0.184-4.749,1.626-6.05,2.472c0,0-2.062-0.846-5.492-0.846	c-3.43,0-5.492,0.846-5.492,0.846c-1.301-0.846-4.348-2.656-6.05-2.472c-1.518,1.86-0.39,6.245-0.39,6.245s-2.147,2.137-2.147,6.245	c0,10.645,9.977,10.533,9.977,10.533s-1.005,1.136-1.225,2.806c-0.696,0.236-1.721,0.528-2.549,0.528	c-2.165,0-3.812-2.105-4.416-3.078c-0.595-0.96-1.815-1.766-2.953-1.766c-0.749,0-1.115,0.375-1.115,0.803s1.05,0.727,1.743,1.521	c1.461,1.674,1.435,5.438,6.641,5.438c0.565,0,1.719-0.139,2.588-0.256c-0.005,1.185-0.007,2.436,0.012,3.167	c0.031,1.242-1.453,1.938-1.453,1.938s0.004,0.012,0.006,0.021C16.369,50.045,10,41.768,10,32C10,19.85,19.85,10,32,10z"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </section>
            </header>
        );
    }
}

export default Welcomer;
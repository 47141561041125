import React, { Component } from 'react';
import DarkModeToggle from './DarkModeToggle';

class Navbar extends Component {
    render() {
        return (

            <div className='w-full flex flex-row fixed justify-between text-white p-0'>
                <div className='flex flex-row mt-4'>
                    {/* <div className='w-48 h-0.5 bg-slate-400 rounded-r self-center'></div>
                    <a className='text-slate-400 content-center ml-2 mb-1' href="mailto:contact@cagataypalaz.com">contact@cagataypalaz.com</a> */}
                </div>
                <div className='w-fit place-self-center mr-3 mt-3'>
                    <DarkModeToggle />
                </div>
            </div>
            // <nav className="flex sm:justify-center space-x-4">
            //     {[
            //         ['Home', '/dashboard'],
            //         ['Team', '/team'],
            //         ['Projects', '/projects'],
            //         ['Reports', '/reports'],
            //     ].map(([title, url]) => (
            //         <a href={url} className="rounded-lg px-3 py-2 text-slate-700 font-medium hover:bg-slate-100 hover:text-slate-900">{title}</a>
            //     ))}
            // </nav>

        );
    }
}

export default Navbar;
import React, { Component } from 'react';

class Footer extends Component {
    render() {
        const date = new Date
        const year = date.getFullYear();

        return (

            <div className='w-full justify-center bg-zinc-300 dark:bg-gray-800 flex text-white p-4 ease-in-out transition-all duration-400 text-zinc-600 dark:text-zinc-100 font-semibold'>
                <p className='text-center'>© {year} Çağatay Palaz. All rights reserved.</p>
            </div>
           

        );
    }
}

export default Footer;
import React, { Component } from 'react';
import Hologram from './Hologram';


class Databank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDarkMode: document.documentElement.classList.contains('dark'),
            active: false,
        };
        this.checkboxRef = React.createRef();
    }

    updateDarkModeStatus = () => {
        const isDarkMode = document.documentElement.classList.contains("dark");
        this.setState({ isDarkMode });
    }

    componentDidMount() {
        this.observer = new MutationObserver(() => {
            this.updateDarkModeStatus();
        })

        this.observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ["class"]
        });

        window.addEventListener('keydown', this.handleKeyDown)
    }

    componentWillUnmount() {
        if (this.observer) this.observer.disconnect();
        window.removeEventListener("keydown", this.handleKeyDown);
    }


    handleKeyDown = (event) => {

        if (event.key === "f" || event.key === "F") {
            if (this.checkboxRef.current) {
                this.checkboxRef.current.checked = !this.checkboxRef.current.checked;
                this.checkboxRef.current.dispatchEvent(new Event("change", { bubbles: true }))
            }

            this.setState(prevState => ({
                active: !prevState.active // Toggle lightsaber on and off
            }));
        }
    }

    handleButtonDown = (e) => {
        e.preventDefault();

        if (this.checkboxRef.current) {
            this.checkboxRef.current.checked = !this.checkboxRef.current.checked;
            this.checkboxRef.current.dispatchEvent(new Event("change", { bubbles: true }))
        }

        this.setState(prevState => ({
            active: !prevState.active // Toggle lightsaber on and off
        }));
    }

    render() {
        const { isDarkMode, active } = this.state;

        return (
            <section className='transition-all duration-300 ease-in-out bg-stone-200 dark:bg-gray-900 dark:text-zinc-100 text-zinc-600 w-full h-full overflow-hidden content-center py-[10rem]'>
                <div className='flex flex-col sm:flex-row px-5 mx-auto my-auto justify-center items-center sm:justify-between md:w-m sm:w-s lg:w-l 2xl:w-xl'>
                    <div className={`flex flex-col mr-42 relative justify-center flex-2 order-2 sm:order-1 ${active ? "mt-24" : "mt-0"} transition-all duration-1000 ease-in-out`}>
                        <p className='font-medium text-2xl'>May the <span className='text-blue-500 dark:text-red-500'>FORCE</span> be with you!</p>
                        <div onClick={this.handleButtonDown} className='dark:border-zinc-100 border-gray-900 w-10 h-10 bg-transparent border-2 rounded-md relative cursor-pointer mt-3 '>
                            <p className='font-semibold absolute top-0 left-1 p-0 m-0'>F</p>
                        </div>
                    </div>
                    <div className={`w-64 relative transition-all duration-1000 ease-in-out order-1 ${active ? "top-20" : "-top-6"}`}>
                        <Hologram isDarkMode={isDarkMode} checkboxRef={this.checkboxRef} />
                    </div>
                </div>
            </section>
        );
    };
}

export default Databank;

const projects = [
    {
        id: 1,
        title: "This Portfolio",
        company: "cagataypalaz.com",
        duration: "Jan 2022 - Present",
        url: "",
        description: "Designed and developed my personal portfolio website to showcase my skills, projects, and experience. Highlights my expertise in web development, UI/UX design, and technology leadership.",
        img: "cags.png"
    },
    {
        id: 2,
        title: "Airport Taxi Melbourne",
        company: "airporttaximelbourne.com",
        duration: "Jun 2020 - Dec 2021",
        url: "https://airporttaximelbourne.com/",
        description: "Designed and developed a fully optimized website for Airport Taxi Melbourne, a leading taxi service in Australia. The platform enhances customer experience, streamlines online bookings, and improves accessibility for passengers.",
        img: "atm.png"
    },
    {
        id: 3,
        title: "ECG Media",
        company: "ecgmedia.com",
        duration: "Jan 2019 - May 2020",
        url: "https://ecgmedia.org/",
        description: "Developed and manage the website for ECG Media, a digital solutions platform specializing in social media marketing, web development, and content strategy. As CTO, I oversee technology and innovation to drive business growth and digital success.",
        img: "ecgmedia.png"
    },
    {
        id: 4,
        title: "Bourne Constructions",
        company: "demo.ecgmedia.org",
        duration: "Jan 2019 - May 2020",
        url: "https://demo.ecgmedia.org/",
        description: "Developed a professional website for Bourne Constructions, a leading construction company offering high-quality services such as tiling, painting, home renovations, electrical work, and landscaping. Designed to showcase their expertise and commitment to excellence.",
        img: "cags.png"
    },
    {
        id: 5,
        title: "Legendary Windows",
        company: "legendarywindows.com.au",
        duration: "Jan 2019 - May 2020",
        url: "https://legendarywindows.com.au/",
        description: "Developed a professional website for UPVC Windows & Doors, showcasing their premium European-inspired double-glazed windows and doors. Designed to highlight their energy-efficient solutions, Australian manufacturing, and 30+ years of industry expertise.",
        img: "legendarywindows.png"
    },
    {
        id: 6,
        title: "Lockup & Fixing",
        company: "lockupfixing.com.au",
        duration: "Jan 2019 - May 2020",
        url: "https://lockupfixing.com.au/",
        description: "Developed a fully functional online store for Lockup & Fixing, streamlining product listings and e-commerce management. Managed the item catalog and optimized the platform for seamless user experience and efficient transactions.",
        img: "lockupfixing.png"
    },
    {
        id: 7,
        title: "Melbourne Truss",
        company: "Private",
        duration: "Jan 2019 - May 2020",
        url: "",
        description: "Developed a courier application for Melbourne Truss, integrating SMS notifications to update customers on delivery status. Implemented a comprehensive product and delivery tracking system to enhance logistics efficiency and customer experience.",
        img: "delivery.png"
    },
    {
        id: 8,
        title: "Lockup & Fixing",
        company: "Private",
        duration: "Jan 2019 - May 2020",
        url: "",
        description: "Developed a courier application for Lockup & Fixing, integrating SMS notifications to update customers on delivery status. Implemented a comprehensive product and delivery tracking system to enhance logistics efficiency and customer experience.",
        img: "delivery.png"
    },
    {
        id: 9,
        title: "Promo Doors",
        company: "Private",
        duration: "Jan 2019 - May 2020",
        url: "",
        description: "Developed a courier application for Promo Doors with integrated SMS notifications to update customers on order progress. Built a robust product and delivery tracking system along with a production assignment and tracking system to optimize workflow and enhance operational efficiency.",
        img: "delivery.png"
    },
    {
        id: 10,
        title: "Promo Doors",
        company: "Private",
        duration: "Jan 2019 - May 2020",
        url: "",
        description: "Developed a stock tracking application for Promo Doors to streamline inventory management and improve operational efficiency. The system enables real-time monitoring of stock levels, ensuring accurate tracking and optimized resource allocation.",
        img: "promodoors.png"
    }
];

export default projects;

const experiences = [
    {
        id: 1,
        title: "Chief Technology Officer",
        company: "ECG Media",
        duration: "Apr 2024 - Present",
        url: "https://ecgmedia.org/",
        description: "Leading the technology and digital strategy for ECG Media, overseeing web development, digital solutions, and innovative tech initiatives. Responsible for scaling infrastructure, optimizing performance, and driving technical innovation.",
        img: "ecgmedia.png"
    },
    {
        id: 2,
        title: "Turkish Language Coordinator",
        company: "Medal.tv",
        duration: "Aug 2023 - Present",
        url: "https://medal.tv/",
        description: "Managing and overseeing Turkish language localization efforts for Medal.tv, ensuring high-quality translations and culturally relevant content. Working closely with community members and developers to improve user experience for Turkish-speaking audiences.",
        img: "medaltv.png"
    },
    {
        id: 3,
        title: "Community & Website Moderator",
        company: "Top.gg",
        duration: "Nov 2020 - Present",
        url: "https://top.gg/",
        description: "Moderating and maintaining the integrity of Top.gg’s platform, ensuring a safe and engaging experience for users. Managing website content, enforcing guidelines, and assisting in community management to support platform growth.",
        img: "topgg.png"
    },
];

export default experiences;

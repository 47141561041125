import React from 'react';
import projects from "../assets/projects";

function Experience() {

    const handleProjectClick = (e) => {
        e.preventDefault();
        const projectId = parseInt(e.target.id); // Convert ID to number
        const project = projects.find((p) => p.id === projectId); // Find project by ID

        if (project && project.url.length > 0) {
            window.open(project.url, "_blank"); // Open URL in a new tab
        }

        return;
    };


    return (
        <div className="h-full flex bg-stone-200 dark:bg-gray-900 dark:text-zinc-100 text-zinc-600 transition-all duration-300 ease-in-out">


            <section className='flex flex-col px-5 pt-[5rem] text-left my-auto mx-auto w-full md:w-m sm:w-s sm:h-3/5 md:h-3/5 lg:h-3/5 lg:w-l 2xl:h-3/5 2xl:w-xl'>
                <div className='relative flex flex-col mb-12'>
                    <h2 className='text-stone-400 dark:text-gray-700 w-full text-4xl sm:text-5xl md:text-6xl font-semibold -top-16 z-0 select-none pointer-events-none'>Galaxy of Code</h2>
                    <p className='z-10'>From Padawan to Master, My Adventures in Development</p>
                </div>

                <div className="flex flex-wrap gap-4">
                    {projects.map((job) => (
                        <div
                            key={job.id}
                            id={job.id}
                            onClick={handleProjectClick}
                            className={`flex flex-row select-none w-full p-4 bg-gray-300 dark:bg-gray-800 border-4 border-transparent hover:border-b-blue-600 dark:hover:border-b-red-500 text-white text-start rounded-lg hover:rounded-b-none ease-in-out transition-all duration-400 ${job.url.length > 0 ? "cursor-pointer" : "cursor-default"}`}
                        >

                            <div className='flex flex-col pointer-events-none select-none'>
                                <div className="flex flex-row">
                                    <div className="h-12 mr-3">
                                        <img className="h-full rounded" src={`/${job.img}`} alt="" />
                                    </div>
                                    <div className="h-max">
                                        <h2 className="text-lg font-bold dark:text-zinc-100 text-zinc-800">{job.title}</h2>
                                        <p className="text-sm dark:text-gray-400 text-zinc-600">{job.company}</p>
                                    </div>
                                </div>

                                <p className="text-xs italic dark:text-gray-400 mt-4 mb-0 text-zinc-600">{job.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

            </section>
        </div>
    );

}

export default Experience;
import React, { useState, useEffect } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';

const DarkModeToggle = () => {
  const [darkMode, setDarkMode] = useState(false);

  // Load initial mode from localStorage or system preference
  useEffect(() => {
    const storedMode = localStorage.getItem('theme');
    if (storedMode === 'dark' || (!storedMode && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      setDarkMode(true);
      document.documentElement.classList.add('dark');
    } else {
      setDarkMode(false);
      document.documentElement.classList.remove('dark');
    }
  }, []);

  const toggleDarkMode = () => {
    if (darkMode) {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    } else {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    }
    setDarkMode(!darkMode);
  };

  return (
    <div className="flex justify-center items-center">
      <div
        className={`w-16 h-8 flex items-center bg-gray-300 dark:bg-gray-700 rounded-full p-1 cursor-pointer transition-all duration-300 ease-in-out`}
        onClick={toggleDarkMode}
      >
        <div
          className={`w-6 h-6 rounded-full bg-white dark:bg-gray-900 shadow-md flex items-center justify-center transform transition-all duration-300 ease-in-out ${darkMode ? 'translate-x-8' : 'translate-x-0'}`}
        >
          {darkMode ? (
            <FaMoon className="text-yellow-500 transition-all duration-300 ease-in-out" />
          ) : (
            <FaSun className="text-yellow-500 transition-all duration-300 ease-in-out" />
          )}
        </div>
      </div>
    </div>
  );
};

export default DarkModeToggle;

import React, { Component } from 'react';
import '../css/Hologram.css';

import HologramSVGWrapper from "./HologramSVGWrapper"

class Hologram extends Component {
    render() {
        const { isDarkMode, checkboxRef } = this.props;

        return (
            <div className="transition-all duration-300 ease-in-out dark:text-zinc-100 text-zinc-600">
                <input type="checkbox" id="active" className="active" ref={checkboxRef} style={{ display: "none" }}></input>

                <div className={isDarkMode ? "container" : "container dark-container"}>

                    <div className="cube-base active">
                        <div className={isDarkMode ? "holo" : "holo holo-dark"}>
                            <div className={isDarkMode ? "holo-image" : "holo-image holo-image-dark"}>
                                <HologramSVGWrapper darkMode={isDarkMode} />
                            </div>
                            <div className={isDarkMode ? "holo-shine" : "holo-shine-dark"}>
                                <svg version="1.1" id="Capa_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" enableBackground="new 0 0 20 20">
                                    <defs>
                                        <radialGradient id="gradient" cx="50%" cy="90%" r="70%" fx="50%" fy="50%">
                                            <stop offset="0%" style={{ stopColor: isDarkMode ? "rgb(253, 162, 162)" : "rgb(162, 229, 253)", stopOpacity: ".65" }} />
                                            <stop offset="70%" style={{ stopColor: isDarkMode ? "rgb(253, 162, 162)" : "rgb(162, 229, 253)", stopOpacity: "0" }} />
                                        </radialGradient>
                                        <filter id="blur" x="0" y="0">
                                            <feGaussianBlur in="SourceGraphic" stdDeviation=".5" />
                                        </filter>
                                    </defs>
                                    <polygon fill="#FFFFFF" points="14,20 6,20 0,0 20,0 " />
                                </svg>
                            </div>
                        </div>
                        <div className="cube-face face-one">
                            <div className="cube-face bevel"></div>
                            <div className="cube-face face-two">

                                <div className="cube-face face-three">
                                    <div className="label">
                                        <em>CAGATAY PALAZ</em>
                                        <div>

                                            <svg version="1.1" id="Capa_1" x="0px" y="0px" width="21px" height="21px" viewBox="-0.5 -0.5 21 21" enableBackground="new -0.5 -0.5 21 21">
                                                <path d="M9.869-0.369C4.148-0.369-0.5,4.279-0.5,9.999c0,5.721,4.648,10.37,10.369,10.37s10.369-4.649,10.369-10.37
                                        C20.238,4.279,15.59-0.369,9.869-0.369z M10.382,0.703c3.062,0.179,5.722,1.81,7.286,4.224l-0.446,0.268
                                        c-0.335-0.514-0.693-0.961-1.117-1.385l-0.738,0.737c-0.96-0.96-2.167-1.676-3.531-2.033l0.269-1.006
                                        c-0.559-0.157-1.14-0.247-1.722-0.268V0.703z M9.355,0.703v0.514c-0.603,0.044-1.162,0.134-1.72,0.269l0.268,1.006
                                        c-1.363,0.357-2.57,1.072-3.53,2.034L3.634,3.787C3.231,4.212,2.853,4.659,2.517,5.172L2.07,4.904
                                        C3.634,2.513,6.294,0.882,9.355,0.703z M1.579,14.223C0.93,12.949,0.572,11.52,0.572,9.999c0-1.497,0.358-2.927,0.984-4.179
                                        l0.446,0.269C1.735,6.603,1.533,7.162,1.377,7.698l1.006,0.268C2.204,8.614,2.114,9.307,2.114,9.999c0,0.715,0.09,1.387,0.269,2.034
                                        l-1.006,0.268c0.156,0.559,0.358,1.118,0.648,1.655L1.579,14.223z M9.355,19.295c-3.039-0.156-5.699-1.788-7.263-4.178l0.448-0.269
                                        c0.313,0.493,0.692,0.939,1.095,1.342l0.738-0.738c0.96,0.961,2.167,1.677,3.53,2.034l-0.268,1.004
                                        c0.559,0.157,1.139,0.247,1.72,0.269V19.295z M9.869,16.19c-0.402,0-0.782-0.045-1.14-0.113l0.581-3.038
                                        c-0.715-0.135-1.318-0.492-1.766-1.027l-2.345,2.01c-0.247-0.29-0.47-0.603-0.671-0.938c-0.201-0.334-0.358-0.693-0.491-1.05
                                        l2.927-1.028c-0.111-0.313-0.179-0.67-0.179-1.028s0.068-0.693,0.179-1.028L4.037,7.921C4.17,7.564,4.327,7.206,4.528,6.87
                                        c0.202-0.334,0.425-0.647,0.671-0.938l2.345,2.011c0.448-0.514,1.073-0.894,1.766-1.027L8.729,3.876
                                        c0.38-0.067,0.759-0.112,1.14-0.112c0.38,0,0.782,0.045,1.14,0.112l-0.582,3.039c0.715,0.134,1.319,0.492,1.766,1.027l2.346-2.011
                                        c0.245,0.292,0.469,0.604,0.67,0.938c0.201,0.335,0.357,0.694,0.491,1.051l-2.927,1.028c0.112,0.313,0.179,0.67,0.179,1.028
                                        s-0.067,0.693-0.179,1.028l2.927,1.028c-0.134,0.357-0.29,0.716-0.491,1.05c-0.201,0.335-0.425,0.648-0.67,0.938l-2.346-2.01
                                        c-0.447,0.513-1.073,0.892-1.766,1.027l0.582,3.038C10.651,16.145,10.271,16.19,9.869,16.19z M10.382,19.295v-0.513
                                        c0.604-0.044,1.163-0.134,1.722-0.268l-0.269-1.007c1.363-0.358,2.57-1.073,3.531-2.034l0.738,0.738
                                        c0.401-0.403,0.759-0.849,1.095-1.34l0.447,0.267C16.059,17.507,13.422,19.118,10.382,19.295z M17.712,13.956
                                        c0.269-0.537,0.492-1.096,0.648-1.655l-1.005-0.268c0.179-0.647,0.268-1.341,0.268-2.034c0-0.715-0.089-1.385-0.268-2.034
                                        l1.005-0.268c-0.157-0.536-0.357-1.095-0.626-1.608l0.447-0.269c0.626,1.251,0.984,2.682,0.984,4.179
                                        c0,1.521-0.358,2.95-1.006,4.224L17.712,13.956z" />
                                            </svg>
                                            <svg version="1.1" id="Capa_1" x="0px" y="0px" width="21px" height="21px" viewBox="-0.5 -0.5 21 21" enableBackground="new -0.5 -0.5 21 21" >
                                                <path d="M7.394,0.554c0,0.149-0.12,0.273-0.27,0.274C6.75,0.831,3.478,0.958,3.478,4.441V5.29c0,0.15-0.12,0.272-0.272,0.272
                                        S2.934,5.44,2.934,5.29V4.441c0-4.009,3.757-4.155,4.184-4.158H7.12C7.27,0.284,7.392,0.404,7.394,0.554z M15.93,7.458
                                        c0.047,0.077,0.058,0.174,0.02,0.258c-0.08,0.185-0.816,1.794-1.75,1.794c-0.828,0-3.656-1.494-3.975-1.664
                                        c-0.11-0.058-0.167-0.186-0.135-0.306c0.029-0.121,0.137-0.206,0.264-0.206h5.349C15.793,7.336,15.879,7.382,15.93,7.458z
                                         M15.26,7.88h-3.778c1.086,0.537,2.328,1.088,2.719,1.088C14.554,8.968,14.986,8.37,15.26,7.88z M8.429,7.534
                                        C8.46,7.651,8.412,7.776,8.307,7.84c-0.279,0.17-2.763,1.671-3.599,1.671c-0.935,0-1.668-1.611-1.75-1.794
                                        c-0.037-0.085-0.03-0.181,0.021-0.259c0.051-0.076,0.135-0.123,0.228-0.123h4.959C8.287,7.336,8.393,7.416,8.429,7.534z M7.159,7.88
                                        H3.645c0.297,0.534,0.728,1.088,1.063,1.088C5.095,8.968,6.205,8.415,7.159,7.88z M2.761,13.653
                                        c-0.464,0.087-0.89,0.286-1.265,0.595c-0.118,0.094-0.135,0.264-0.04,0.381c0.054,0.066,0.132,0.102,0.21,0.102
                                        c0.061,0,0.12-0.021,0.171-0.063c0.306-0.246,0.649-0.409,1.024-0.478c0.146-0.028,0.244-0.172,0.216-0.319
                                        C3.05,13.723,2.907,13.624,2.761,13.653z M1.62,13.529c0.03,0,0.059-0.006,0.091-0.016c0.245-0.084,0.511-0.129,0.785-0.129
                                        c0.149,0,0.274-0.122,0.274-0.272c0-0.149-0.125-0.271-0.274-0.271c-0.335,0-0.661,0.055-0.964,0.16
                                        c-0.142,0.049-0.217,0.205-0.168,0.346C1.401,13.459,1.508,13.529,1.62,13.529z M18.646,11.448c1.442,1.671,1.231,2.694-1.016,4.894
                                        c-0.027,0.411-0.225,1.852-1.584,3.209c-0.62,0.621-1.468,0.949-2.427,0.949c-0.151,0-0.303-0.008-0.458-0.023
                                        c-0.892-0.095-1.796-0.467-2.257-0.926c-0.127-0.127-0.268-0.233-0.412-0.326c-0.107,0.215-0.324,0.366-0.58,0.366
                                        c-0.147,0-0.278-0.058-0.388-0.142c-0.11,0.084-0.24,0.142-0.39,0.142c-0.248,0-0.456-0.145-0.567-0.347
                                        c-0.144,0.092-0.285,0.18-0.414,0.307c-0.458,0.459-1.365,0.831-2.256,0.926C5.743,20.492,5.591,20.5,5.441,20.5
                                        c-0.958,0-1.808-0.328-2.428-0.949c-1.36-1.357-1.555-2.798-1.585-3.209c-2.247-2.2-2.456-3.223-1.015-4.894
                                        c0.838-0.972,1-1.49,1.009-1.527V7.606c0-0.226,0.063-0.399,0.149-0.546C1.518,7.032,1.471,6.992,1.445,6.93
                                        C1.401,6.829,1.423,6.713,1.5,6.635l0.649-0.673V4.44c0-4.883,4.921-4.94,4.97-4.94h2.354l0,0l0,0h2.448
                                        c0.049,0,4.875,0.057,4.875,4.94v1.513l0.728,0.671c0.083,0.076,0.11,0.194,0.069,0.298c-0.024,0.057-0.062,0.1-0.112,0.13
                                        c0.088,0.149,0.155,0.323,0.155,0.554v2.312C17.646,9.958,17.807,10.474,18.646,11.448z M16.911,10.395
                                        c0.151-0.141,0.179-0.384,0.182-0.423l-0.001-2.364c0-0.222-0.085-0.346-0.181-0.417v0.417V10.395z M2.694,5.801h13.56v-1.36
                                        c0-4.336-4.154-4.396-4.332-4.396H9.476H9.474l0,0H7.12c-0.179,0-4.426,0.06-4.426,4.396V5.801L2.694,5.801z M2.337,6.553h14.308
                                        l-0.228-0.208H2.537L2.337,6.553z M16.365,7.607V7.097H2.694v0.511c0,1.011,0.17,1.74,0.502,2.169
                                        c0.272,0.352,0.651,0.516,1.199,0.516c0.787,0,1.759-0.348,2.701-0.687c0.917-0.327,1.786-0.638,2.49-0.638
                                        c0.71,0,1.571,0.311,2.481,0.64c0.921,0.33,1.871,0.675,2.634,0.675C15.477,10.283,16.365,9.978,16.365,7.607z M15.973,10.432v0.671
                                        c0.104-0.167,0.209-0.334,0.315-0.523c0.018-0.032,0.051-0.04,0.076-0.061v-0.492C16.253,10.188,16.119,10.318,15.973,10.432z
                                         M13.362,13.382c0.217,0,0.479-0.042,0.787-0.191c0.421-0.292,0.86-0.715,1.317-1.331c-0.013-0.031-0.038-0.055-0.038-0.09v-1.061
                                        c-0.219,0.069-0.455,0.116-0.727,0.116c-0.859,0-1.855-0.358-2.817-0.707C11.018,9.806,10.2,9.511,9.586,9.511
                                        c-0.61,0-1.435,0.295-2.307,0.608c-0.986,0.353-2.003,0.718-2.884,0.718c-0.345,0-0.649-0.061-0.917-0.175v1.108
                                        c0,0.012-0.012,0.021-0.015,0.033c0.078,0.101,0.156,0.216,0.229,0.305c0.917,1.122,1.599,1.283,2.072,1.283
                                        c0.606,0,1.178-0.301,1.787-0.624c0.637-0.336,1.299-0.684,2.035-0.684c0.742,0,1.398,0.348,2.035,0.684
                                        C12.213,13.085,12.776,13.382,13.362,13.382z M11.768,13.459v0.157c0.179,0.046,0.363,0.077,0.549,0.093
                                        C12.131,13.637,11.95,13.549,11.768,13.459z M11.009,13.063v0.303c0.07,0.028,0.144,0.055,0.216,0.081v-0.269
                                        C11.152,13.138,11.081,13.101,11.009,13.063z M10.185,12.727v0.321c0.09,0.034,0.182,0.069,0.279,0.106v-0.339
                                        C10.371,12.778,10.276,12.752,10.185,12.727z M9.586,12.628c-0.059,0-0.118,0.014-0.177,0.02v0.246c0.014,0,0.032-0.006,0.045-0.006
                                        c0.059,0,0.124,0.026,0.186,0.034v-0.288C9.624,12.632,9.605,12.628,9.586,12.628z M8.644,12.843v0.234
                                        c0.074-0.027,0.149-0.057,0.22-0.081v-0.23C8.791,12.792,8.718,12.813,8.644,12.843z M7.866,13.219v0.171
                                        c0.079-0.031,0.156-0.061,0.232-0.092v-0.199C8.021,13.138,7.943,13.178,7.866,13.219z M6.921,13.678
                                        c0.136-0.025,0.27-0.058,0.402-0.098v-0.082C7.191,13.563,7.058,13.62,6.921,13.678z M2.768,10.111
                                        c-0.026-0.031-0.049-0.065-0.074-0.101v0.626c0.017,0.028,0.032,0.051,0.048,0.079c0.067,0.112,0.127,0.203,0.192,0.305v-0.738
                                        C2.877,10.226,2.816,10.176,2.768,10.111z M1.966,9.993c0.004,0.024,0.032,0.262,0.183,0.401V7.607V7.191
                                        C2.053,7.264,1.966,7.388,1.966,7.607V9.993z M0.303,14.159c-0.047-0.89,0.436-1.576,0.858-1.996
                                        c0.536-0.535,0.778-0.946,0.894-1.198c-0.167-0.067-0.295-0.174-0.39-0.301c-0.169,0.285-0.427,0.659-0.838,1.137
                                        C0.099,12.646-0.214,13.227,0.303,14.159z M3.739,14.388L2.437,11.42c-0.167,0.295-0.443,0.679-0.892,1.127
                                        c-0.97,0.97-0.937,2.052,0.093,3.222C1.935,15.309,2.594,14.537,3.739,14.388z M8.481,15.879c0-0.061-0.049-0.109-0.11-0.109
                                        s-0.11,0.049-0.11,0.109v2.309c0,0.122,0.22,0.122,0.22,0V15.879z M9.245,15.127c0-0.061-0.047-0.107-0.11-0.107
                                        c-0.061,0-0.11,0.047-0.11,0.107v0.752v2.309v0.751c0,0.12,0.22,0.12,0.22,0V15.127L9.245,15.127z M10.022,15.127
                                        c0-0.061-0.049-0.107-0.11-0.107s-0.11,0.047-0.11,0.107v3.812c0,0.12,0.22,0.122,0.22,0V15.127z M10.845,15.879
                                        c0-0.061-0.049-0.109-0.11-0.109s-0.11,0.049-0.11,0.109v2.309c0,0.122,0.22,0.122,0.22,0V15.879z M17.089,16.283
                                        c-0.115-0.238-0.748-1.378-2.175-1.378c-0.091,0-0.177-0.048-0.226-0.124c-0.051-0.074-0.059-0.174-0.021-0.258l0.697-1.59
                                        c-0.29,0.291-0.577,0.516-0.867,0.674c-1.111,0.788-2.128,0.74-2.997,0.499c-0.002,0-0.002,0.002-0.004,0.002
                                        c-0.006,0-0.01-0.007-0.018-0.009c-0.358-0.102-0.691-0.233-0.992-0.355c-0.411-0.167-0.766-0.311-1.033-0.311
                                        c-0.27,0-0.62,0.145-1.025,0.315c-0.573,0.238-1.257,0.525-2.033,0.525c-0.814,0-1.729-0.332-2.709-1.356l0.707,1.606
                                        c0.038,0.084,0.027,0.184-0.021,0.258c-0.05,0.076-0.137,0.124-0.229,0.124c-1.411,0-2.054,1.14-2.172,1.375
                                        c0.016,0.264,0.16,1.618,1.427,2.888c0.768,0.766,1.753,0.842,2.444,0.768c0.892-0.094,1.625-0.465,1.93-0.768
                                        c0.139-0.14,0.286-0.256,0.439-0.361c-0.279-0.072-0.494-0.316-0.494-0.619v-1.676c-0.234,0.231-0.498,0.47-0.921,0.623
                                        c0.371,0.264,0.609,0.639,0.609,1.061c0,0.791-0.82,1.435-1.828,1.435c-0.002,0-0.004-0.002-0.008-0.002
                                        c-0.006,0-0.012,0.002-0.017,0.002c-0.575,0-1.043-0.468-1.043-1.044l0.002-0.002l-0.002-0.143c-0.002-0.529-0.006-1.078,0.327-1.41
                                        c0.177-0.18,0.427-0.271,0.742-0.271c0.032,0,0.061,0.006,0.093,0.008c0.007-0.002,0.009-0.008,0.017-0.008
                                        c1.01,0,1.338-0.325,1.718-0.7c0.072-0.075,0.146-0.146,0.224-0.217c0.03-0.027,0.069-0.025,0.105-0.037
                                        c0.039-0.325,0.304-0.58,0.641-0.58c0.038,0,0.072,0.016,0.108,0.021v-0.121c0-0.359,0.293-0.652,0.654-0.652
                                        c0.149,0,0.28,0.059,0.39,0.141c0.11-0.082,0.24-0.141,0.39-0.141c0.36,0,0.652,0.293,0.652,0.652v0.133
                                        c0.055-0.015,0.11-0.033,0.171-0.033c0.336,0,0.605,0.261,0.639,0.59c0.014,0.008,0.032,0.004,0.046,0.016
                                        c0.083,0.074,0.157,0.151,0.236,0.227c0.38,0.377,0.707,0.702,1.717,0.702c0.008,0,0.01,0.008,0.019,0.008
                                        c0.031,0,0.059-0.008,0.09-0.008c0.314,0,0.567,0.091,0.742,0.271c0.332,0.332,0.33,0.881,0.328,1.41l-0.001,0.141
                                        c0,0.002,0.001,0.002,0.001,0.004c0,0.578-0.468,1.046-1.044,1.046c-0.006,0-0.01-0.004-0.016-0.004
                                        c-0.003,0-0.003,0.004-0.009,0.004c-1.008,0-1.824-0.646-1.824-1.435c0-0.424,0.236-0.799,0.605-1.062
                                        c-0.396-0.145-0.653-0.36-0.877-0.578v1.631c0,0.315-0.227,0.566-0.525,0.628c0.15,0.103,0.293,0.219,0.427,0.353
                                        c0.305,0.303,1.037,0.672,1.929,0.768c0.69,0.074,1.676-0.002,2.444-0.768C16.928,17.9,17.074,16.549,17.089,16.283z M14.008,18.586
                                        v-0.004c-0.002-0.271-0.224-0.492-0.498-0.492c-0.276,0-0.5,0.224-0.5,0.496c0,0.276,0.224,0.502,0.5,0.502
                                        c0.274,0,0.498-0.224,0.5-0.5C14.01,18.588,14.008,18.588,14.008,18.586z M13.953,17.652c-0.023-0.097-0.059-0.186-0.112-0.238
                                        c-0.073-0.074-0.189-0.107-0.356-0.107c-0.695,0-1.281,0.406-1.281,0.891c0,0.203,0.12,0.385,0.293,0.537
                                        c-0.006-0.049-0.03-0.096-0.03-0.148c0-0.574,0.468-1.039,1.043-1.039C13.668,17.547,13.818,17.588,13.953,17.652z M6.595,18.586
                                        c0,0.053-0.021,0.1-0.032,0.151c0.175-0.155,0.295-0.337,0.295-0.54c0-0.484-0.586-0.891-1.282-0.891
                                        c-0.166,0-0.284,0.035-0.356,0.107c-0.055,0.055-0.086,0.142-0.11,0.238c0.133-0.064,0.281-0.105,0.441-0.105
                                        C6.126,17.547,6.595,18.012,6.595,18.586z M5.55,18.09c-0.274,0-0.497,0.222-0.497,0.494v0.002l-0.002,0.002
                                        c0.002,0.274,0.225,0.5,0.499,0.5c0.277,0,0.5-0.226,0.5-0.502C6.05,18.313,5.827,18.09,5.55,18.09z M17.516,12.547
                                        c-0.451-0.448-0.726-0.832-0.892-1.127l-1.304,2.967c1.146,0.151,1.804,0.921,2.101,1.382C18.453,14.6,18.484,13.518,17.516,12.547z
                                         M18.233,11.802c-0.411-0.479-0.669-0.854-0.837-1.137c-0.097,0.127-0.226,0.234-0.392,0.301c0.115,0.251,0.36,0.663,0.896,1.198
                                        c0.421,0.421,0.904,1.106,0.856,1.996C19.274,13.227,18.962,12.646,18.233,11.802z M16.3,13.653
                                        c-0.146-0.029-0.292,0.069-0.319,0.217s0.069,0.291,0.219,0.319c0.372,0.068,0.716,0.231,1.021,0.478
                                        c0.049,0.042,0.109,0.063,0.171,0.063c0.079,0,0.157-0.035,0.213-0.102c0.095-0.117,0.075-0.287-0.04-0.381
                                        C17.189,13.939,16.763,13.74,16.3,13.653z M16.292,13.112c0,0.152,0.121,0.274,0.271,0.274c0.276,0,0.54,0.043,0.788,0.129
                                        c0.027,0.008,0.06,0.014,0.089,0.014c0.112,0,0.218-0.069,0.258-0.183c0.049-0.14-0.025-0.295-0.167-0.346
                                        c-0.306-0.104-0.631-0.16-0.968-0.16C16.414,12.841,16.292,12.963,16.292,13.112z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="cube-face face-four">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cube-face face-base"></div>
                    </div>

                    <label htmlFor="active" data-text-active="Deactivate" data-text-inactive="Activate" style={{ display: "none" }}></label>
                </div>
            </div>
        );
    };
}

export default Hologram;

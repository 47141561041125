import './App.css';
import Welcomer from './components/Welcomer';
import Navbar from './components/Navbar';
import Experience from './components/Experience';
// import Lightsaber from './components/Lightsaber';
import Databank from './components/Databank';
import Jobs from './components/Jobs';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="bg-stone-200 dark:bg-gray-900">
      <Navbar />
      <Welcomer />
      <Jobs />
      <Experience />
      <Contact />
      <Databank />
      <Footer />
    </div>
  );
}

export default App;

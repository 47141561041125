import React from 'react';
import lb from "../assets/lightsaber.webp";
import '../css/Lightsaber.css';

class Lightsaber extends React.Component {
    render() {
        const { active, toggleLightsaber, darkMode } = this.props; // Destructure active state and toggle function from props


        return (
            <div className='lightsaber-container'>
                <div className='lightsaberx my-0 mx-0'>
                    <img
                        src={lb}
                        className='h-auto w-10 rotate-90 -scale-x-100 z-50 cursor-pointer' // Rotated hilt
                        alt="Lightsaber Hilt"
                        onClick={toggleLightsaber} // Toggle lightsaber
                    />
                    {/* Blade animation */}
                    <div className={`${darkMode ? "r-blade" : "b-blade"} ${active ? 'on' : 'off'}`}></div>
                </div>
            </div>
        );
    }
}

export default Lightsaber;
